import React from "react";
import { AspectRatio, Card, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AddIcon } from "@chakra-ui/icons";

const AndMoreUseCases = () => {
    const { t } = useTranslation();
    return (
        <Card variant={"outline"} bgColor={"background.main"} borderColor={"gray.300"} p={3} w={"20rem"} h={"20rem"} justifyContent={"flex-start"}>
            <VStack color={"text.main"} justifyContent={"flex-start"} alignItems={"center"}>
                <AspectRatio w={"100%"} ratio={16 / 9}>
                    <AddIcon p={"3rem"} />
                </AspectRatio>
                <Text h="3rem" fontWeight={"600"} fontSize={"18px"} textAlign={"center"}>{t("usecases.others.title")}</Text>
                <Text textAlign={"center"} fontWeight={"500"} color={"gray.500"}>
                    {t("usecases.others.description")}
                </Text>
            </VStack>
        </Card>
    )
}

export default AndMoreUseCases;