import * as React from "react"
import {
    Button,
    FormControl,
    FormLabel,
    HStack,
    Heading,
    Input,
    Textarea,
    VStack,
    useToast,
    Alert, AlertIcon, AlertTitle, Text
} from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { sendEmail } from "../services/emailSender";

const Contact = () => {
    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("");
    const [message, setMessage] = React.useState("");
    const createToast = useToast();

    const isEmailValid = React.useMemo(() => {
        const validateEmailAddress = (value: string): boolean => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(value);
        }

        return validateEmailAddress(email)
    }, [email]);

    const isSubmitDisabled = !isEmailValid || !name || !message;

    const handleSubmit = async () => {
        if (isEmailValid) {
            const promise = sendEmail(email, name, message);
            createToast.promise(promise, {
                loading: {
                    title: t("contact.loadingTitle"),
                    description: t("contact.loadingDescription"),
                    duration: 9000,
                    isClosable: true,
                },
                success: {
                    title: t("contact.successTitle"),
                    description: t("contact.successDescription"),
                    duration: 9000,
                    isClosable: true,
                },
                error: {
                    title: t("contact.errorTitle"),
                    description: t("contact.errorDescription"),
                    duration: 9000,
                    isClosable: true,
                },
            }
            );
            const result = await promise;
            if (result) {
                setEmail("");
                setName("");
                setMessage("");
            }
        }
    }

    return (
        <HStack justifyContent={"center"}>
            <VStack alignItems={"left"}>
                <Heading mt={20} textAlign={"left"} fontSize={"40px"}>
                    <Trans i18nKey="contact.title">
                        Contact us at
                    </Trans>
                </Heading>
                <Alert status={"warning"} variant={"left-accent"}>
                    <HStack alignItems={"flex-start"}>
                        <AlertIcon />
                        <VStack alignItems={"flex-start"}>
                            <AlertTitle>
                                <Trans i18nKey={"contact.warning.title"}>Not quite ready yet...</Trans>
                            </AlertTitle>
                            <Text><Trans i18nKey={"contact.warning.description.1"} /></Text>
                            <Text><b><Trans i18nKey={"contact.warning.description.2"} /></b></Text>
                            <Text><b><Trans i18nKey={"contact.warning.description.3"} /></b></Text>
                        </VStack>
                    </HStack>
                </Alert>
                {/*<Heading textAlign={"left"} fontSize={"18px"} fontWeight={"600"} color={"gray.500"}>*/}
                {/*    <Trans i18nKey="contact.description">*/}
                {/*        Contact us FORM*/}
                {/*    </Trans>*/}
                {/*</Heading>*/}
                <FormControl mt={"1rem"} p={"2rem"} py={"3rem"} borderRadius={"1rem"}
                    bg={"gray.100"}
                    onSubmit={handleSubmit}
                >
                    <FormLabel>
                        <Trans i18nKey="contact.email">
                            Email
                        </Trans>
                    </FormLabel>
                    <Input bg={"gray.200"} variant={"outline"} type="email" placeholder={t("contact.emailPlaceholder")}
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        onChange={(e) => setEmail(e.target.value)}
                        isInvalid={(!isEmailValid && name !== "" && email !== "" && message !== "")}
                    />
                    <FormLabel mt={"1rem"}>
                        <Trans i18nKey="contact.name">
                            Name
                        </Trans>
                    </FormLabel>
                    <Input bg={"gray.200"} variant={"outline"} type="text" placeholder={t("contact.namePlaceholder")}
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <FormLabel mt={"1rem"}>
                        <Trans i18nKey="contact.message">
                            Message
                        </Trans>
                    </FormLabel>
                    <Textarea bg={"gray.200"} variant={"outline"} placeholder={t("contact.messagePlaceholder")}
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button bg={isSubmitDisabled ? "gray.200" : "primary.main"}
                        color={isSubmitDisabled ? "gray.300" : "primary.text"}
                        colorScheme="primary"
                        mt={"3rem"}
                        onClick={handleSubmit}
                        disabled={isSubmitDisabled}
                        // change pointer if disabled
                        style={{ cursor: isSubmitDisabled ? "not-allowed" : "pointer" }}
                    >
                        <Trans i18nKey="contact.button">
                            Submit
                        </Trans>
                    </Button>
                </FormControl>

            </VStack>
        </HStack >
    )
}

export default Contact;