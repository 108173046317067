import React from "react";
import {AspectRatio, Card, Image, Text, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

const BehaviourClassificationUseCase = () => {
    const { t } = useTranslation();
    return (
        <Card variant={"outline"} bgColor={"background.main"} borderColor={"gray.300"} p={3} w={"20rem"} h={"20rem"} justifyContent={"flex-start"}>
            <VStack color={"text.main"} justifyContent={"flex-start"} alignItems={"center"}>
                <AspectRatio w={"100%"} ratio={16 / 9}>
                    <Image src={`${process.env.PUBLIC_URL}/cctv/behaviour_classification.gif`} objectFit={"cover"} borderRadius={"xl"}/>
                </AspectRatio>
                <Text h="3rem" fontWeight={"600"} fontSize={"18px"} textAlign={"center"}>{t("usecases.4.title")}</Text>
                <Text textAlign={"center"} fontWeight={"500"} color={"gray.500"}>
                    {t("usecases.4.description")}
                </Text>
            </VStack>
        </Card>
    )
}

export default BehaviourClassificationUseCase;