import axios from 'axios';

export const sendEmail = async (email: string, name: string, message: string) => {
    try {
        await axios.post('https://northamerica-northeast1-boxcv-iws.cloudfunctions.net/boxcv-iws-email-sender', {
            email: email,
            name: name,
            message: message
        });
        return true;
    } catch (error) {
        console.error("Error sending email", error);
        return false;
    }
}