import { Box, Button, Card, Text, VStack } from "@chakra-ui/react";
import React, { ReactNode } from "react";

type Props = {
    title: string,
    description: string,
    icon: ReactNode,
    buttonText?: string
}

const Step = ({ title, description, icon, buttonText }: Props) => {
    return (
        <Card bg="white" p={"3"} w="20rem" h={"20rem"} justifyContent={"center"} >
            <VStack color="text.main" justifyContent={"space-between"} alignItems={"center"}>
                <Box
                    h="3.5rem"

                >
                    {icon}
                </Box>
                <Text h="3rem" fontWeight={"600"} fontSize={"18px"}>{title}</Text>
                <VStack h="5rem" alignItems={"center"}>
                    <Text textAlign={"center"} fontWeight={"500"} color={"gray.500"}>
                        {description}
                    </Text>
                    {buttonText && <div><Button color="secondary" variant={"ghost"}>{buttonText}</Button></div>}
                </VStack>
            </VStack>
        </Card>
    )
}

export default Step;