import * as React from "react"
import {
  ChakraProvider,
  VStack,
} from "@chakra-ui/react"
import theme from "./theme"
import TopBar from "./layout/TopBar"
import Home from "./content/Home"
import Platform from "./content/Platform"
import Contact from "./content/Contact"
import How from "./content/How"
import Usecases from "./content/Usecases"
import Content from "./shared/Content"
import { IDS } from "./shared/Constants"
import About from "./content/About"
import ReactGA from "react-ga";
import CookieConsent from "./shared/CookieConsent/CookieConsent";

ReactGA.initialize("G-S48777ZCB2")

export const App = () => {
  const [currentTab, setCurrentTab] = React.useState("home");

  return (
    <ChakraProvider theme={theme}>
      <CookieConsent current_tab={currentTab}/>
      <TopBar currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <VStack spacing={8} >
        <Content index={0} id={IDS.HOME} title="Home" setCurrentTab={setCurrentTab}
          isCurrentTab={currentTab === IDS.HOME}
        >
          <Home />
        </Content>
        <Content index={1} id={IDS.PLATFORM} title="Platform" setCurrentTab={setCurrentTab}

          isCurrentTab={currentTab === IDS.PLATFORM}
        >
          <Platform />
        </Content>
        <Content index={2} id={IDS.PLATFORM} title="Platform" setCurrentTab={setCurrentTab}
          isCurrentTab={currentTab === IDS.PLATFORM}>
          <How />
        </Content>
        <Content index={4} id={IDS.USE_CASES} title="Usecases" setCurrentTab={setCurrentTab}
          isCurrentTab={currentTab === IDS.USE_CASES}>
          <Usecases />
        </Content>
        <Content index={4} id={IDS.ABOUT} title="About" setCurrentTab={setCurrentTab}
          isCurrentTab={currentTab === IDS.ABOUT}>
          <About />
        </Content>
        <Content index={5} id={IDS.CONTACT} title="Contact" setCurrentTab={setCurrentTab} isCurrentTab={currentTab === IDS.CONTACT}>
          <Contact />
        </Content>
      </VStack>
    </ChakraProvider>
  )
}
