import { IDS } from "../shared/Constants";

export const tabs = [
    {
        name: "Platform",
        key: "platform",
        path: `#${IDS.PLATFORM}`
    },
    {
        name: "Usecases",
        key: "usecases",
        path: `#${IDS.USE_CASES}`
    },
    {
        name: "About",
        key: "about",
        path: `#${IDS.ABOUT}`
    },
]