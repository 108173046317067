import * as React from "react"
import {
    Heading,
    Text,
    VStack,
    HStack,
    Avatar, Button, Link, Wrap, WrapItem
} from "@chakra-ui/react";

import { Trans } from "react-i18next";
import { FaLinkedin } from "react-icons/all";

const About = () => {
    return (
        <>
            <VStack alignItems={"left"}>
                <Heading textAlign={"left"} fontSize={"25px"} color="primary.main">
                    <Trans i18nKey="about.title">about title
                    </Trans>
                </Heading>
                <VStack alignItems={"left"} marginBottom={"5rem"} marginTop={"1rem"}>
                    <Heading textAlign={"left"} fontSize={"40px"} >
                        <Trans i18nKey="about.1.title">
                            about 1 title
                        </Trans>
                    </Heading>
                    <Text textAlign={"left"} fontSize={"18px"} fontWeight={"600"} color={"gray.500"}
                        __css={{
                            p: {
                                paddingTop: "2rem",
                            }
                        }}>
                        <Trans i18nKey="about.1.description">
                            about 1 description
                        </Trans>
                    </Text>
                </VStack>

                <VStack alignItems={"left"}>
                    <Heading textAlign={"left"} fontSize={"40px"}>
                        <Trans i18nKey="about.2.title">
                            Applications
                        </Trans>
                    </Heading>
                    <Text textAlign={"left"} fontSize={"18px"} fontWeight={"600"} color={"gray.500"}>
                        <Trans i18nKey="about.2.description">
                            about 2 description
                        </Trans>
                    </Text>
                    <Wrap justify={"center"} mt={"4rem"} mb={"4rem"} spacing={"4em"}>
                        <WrapItem>
                            <VStack justifyContent={"center"}>
                                <Avatar boxSize={"10rem"} src={`${process.env.PUBLIC_URL}/alex.jpg`} />
                                <Heading fontSize={"18px"}>Alexandre Potvin-Demers</Heading>
                                <HStack justifyContent={"center"}>
                                    <Link href={"https://linkedin.com/in/alexpotv"}>
                                        <Button colorScheme={"linkedin"} leftIcon={<FaLinkedin />}>
                                            LinkedIn
                                        </Button>
                                    </Link>
                                </HStack>
                            </VStack>
                        </WrapItem>
                        <WrapItem>
                            <VStack justifyContent={"center"}>
                                <Avatar boxSize={"10rem"} src={`${process.env.PUBLIC_URL}/marc.jpeg`} />
                                <Heading fontSize={"18px"}>Marc-Antoine Robin</Heading>
                                <HStack justifyContent={"center"}>
                                    <Link href={"https://www.linkedin.com/in/marc-antoine-robin/"}>
                                        <Button colorScheme={"linkedin"} leftIcon={<FaLinkedin />}>
                                            LinkedIn
                                        </Button>
                                    </Link>
                                </HStack>
                            </VStack>
                        </WrapItem>
                    </Wrap>
                    <Text textAlign={"left"} fontSize={"18px"} fontWeight={"600"} color={"gray.500"}>
                        <Trans i18nKey={"about.2.descriptionPart2"}>
                            about 2 description next part
                        </Trans>
                    </Text>
                </VStack>
            </VStack>
        </>
    )
}

export default About;