import * as React from "react"
import {
  keyframes,
  ImageProps,
  forwardRef,
  usePrefersReducedMotion, Image,
} from "@chakra-ui/react"
import logo from "../resources/boxcv.png"

const upAndDown = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
`

type LogoProps = ImageProps & {
  animate?: boolean
}

export const Logo = forwardRef<LogoProps, "img">((props, ref) => {
  const prefersReducedMotion = usePrefersReducedMotion()

  const animation = prefersReducedMotion
    ? undefined
    : `${upAndDown} infinite 3s linear`

  return <Image src={logo} ref={ref} {...props} animation={props.animate ? animation : undefined} />
})
