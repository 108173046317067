import { extendTheme } from "@chakra-ui/react";


const theme = extendTheme({
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false,
    },
    colors: {
        primary: {
            main: '#F9627D',
            200: '#F9627D',
            text: '#FFF',
            shadow: '#0a7e6d'
        },
        secondary: {
            main: '#C1666B',
            shadow: '#8200fd'
        },
        background: {
            main: '#e4ebf3',
            paper: `#1B263B`
        },
        text: {
            main: '#133C55',
            secondary: '#c5cdd8',
            dark: '#0D1B2A'
        },
        error: {
            main: '#dc0044'
        },
    },
    styles: {
        global: (props: any) => ({
            body: {
                background: `${props.theme.colors.background.main}`,
                fontFamily: "Open Sans, sans-serif",
            },
            html: {
                scrollBehavior: 'smooth',
                scrollPaddingTop: '4.5rem',
            }
        })
    },
})

export default theme;