import { Box, VStack } from "@chakra-ui/react"
import * as React from "react"

type Props = {
    index: number,
    id: string,
    title?: string,
    isCurrentTab: boolean,
    setCurrentTab: (tab: string) => void,
    children: React.ReactNode
}

const Content = ({ index, id, isCurrentTab, setCurrentTab, children }: Props) => {
    return (
        <Box
            w="100%"
            p={4}
            id={id}
            justifyContent={"center"}
            display={"flex"}
        >
            <VStack
                minH={"50rem"}
                maxW={"1200px"}
                p={10}
                color={"text.main"}
                onMouseEnter={() => {
                    setCurrentTab(id === "home" ? "" : id)
                }}
                alignItems={"center"}
            >
                {children}
            </VStack>
        </Box >
    )
}

export default Content;